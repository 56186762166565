







import { SendMessageRequest } from "./SendMessageRequest";

import { SplitTags } from 'piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers'

import { Component, Prop } from 'vue-property-decorator';
import Vue from "vue";
import VueI18n from "vue-i18n";

@Component({
})
export default class SendMessageRequestPreview extends Vue {
  @Prop() model!: SendMessageRequest

  @Prop() i18n!: VueI18n

  get message() {
    if (this.model.message.length) {
      const [ first ] = this.model.message

      if (first.text.trim().length) {
        return SplitTags(first.text)
      }
    }

    return this.i18n.t('message_is_not_configured_error').toString()
  }
}
